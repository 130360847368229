<template>
  <v-row>
    <v-col cols="12" md="6" lg="2">
      <v-select
        clearable
        multiple
        v-model="params.departments"
        :items="departments"
        label="Department"
        item-text="name"
        item-value="id"
        outlined
        hide-details
        dense
        @change="$emit('onSearch')"
      ></v-select>
    </v-col>
    <v-col cols="12" md="6" lg="2">
      <v-select
        clearable
        v-model="params.type"
        :items="[
          { text: 'Vector', value: 'vector' },
          { text: 'Raster', value: 'raster' },
        ]"
        label="Type"
        outlined
        hide-details
        dense
        @change="$emit('onSearch')"
      ></v-select>
    </v-col>
    <v-col cols="12" md="6" lg="3">
      <v-checkbox
        @change="$emit('onSearch')"
        hide-details
        dense
        v-model="params.isFavorite"
        label="Show favorite layers"
      />
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
export default {
  props: ['params', 'options'],

  computed: {
    ...mapState('category', ['departments']),
  },
}
</script>
<style lang=""></style>
