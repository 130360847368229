<template>
  <v-dialog v-model="showDialog" persistent max-width="450px">
    <v-card :loading="loading">
      <v-card-title>
        <span>{{ title }}</span>
      </v-card-title>
      <v-card-text class="py-0">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="form.name"
                  label="Name"
                  dense
                  :rules="[v => !!v || 'Name is required']"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="form.departmentId"
                  label="Department"
                  dense
                  :items="departments"
                  item-text="name"
                  item-value="id"
                  clearable
                ></v-select>
                <span style="color: var(--v-error-base); font-size: 13px"
                  >(*) If leave this field empty, all departmetns can see the layer</span
                >
              </v-col>
              <v-col cols="12" v-if="!editing">
                <file-upload
                  v-if="showDialog"
                  :extensions="extensions.map(e => e.name)"
                  :accept="extensions.map(e => '.' + e.name).join(',')"
                  style="border-radius: 9px; width: 100%; height: 130px; border: 1px dashed var(--v-primary-base)"
                  ref="upload-layer"
                  v-model="files"
                  @input-filter="inputFilter"
                  :drop="true"
                >
                  <template>
                    <div style="cursor: pointer; transform: translate(0, 50%)">
                      <v-icon>{{ icons.mdiFileUploadOutline }}</v-icon>
                      <p class="mt-2 font-weight-bold">Drag file to here or browse</p>
                    </div>
                    <p
                      style="position: absolute; bottom: 0; width: 100%; font-size: 13px; z-index: 2"
                      class="mb-0 text-center py-2"
                      v-if="files.length"
                    >
                      {{ files[0].name }} ({{ files[0].size | size }})
                      <v-icon @click="files = []" size="16" color="error">{{ icons.mdiDeleteOutline }}</v-icon>
                    </p>
                  </template>
                </file-upload>
                <span style="color: var(--v-error-base); font-size: 13px"
                  >(*) Raster: tif, tiff (up to 11 GB), vector: SHP zip, geojson (up to 20 MB)</span
                >
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog"> Cancel </v-btn>
        <v-btn v-if="!editing" color="primary" text @click="createData"> Create </v-btn>
        <v-btn v-else color="primary" text @click="updateData"> Update </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { update } from '@/api/layer'
import dialogMixin from '@/mixins/crud/dialog'
import { mapState } from 'vuex'
import VueUploadComponent from 'vue-upload-component'
import { mdiFileUploadOutline, mdiCheckCircleOutline, mdiDeleteOutline } from '@mdi/js'
export default {
  components: {
    'file-upload': VueUploadComponent,
  },
  mixins: [dialogMixin(null, update)],
  data() {
    return {
      icons: { mdiFileUploadOutline, mdiCheckCircleOutline, mdiDeleteOutline },
      files: [],
      extensions: [
        {
          name: 'tif',
          size: 11 * 1024 * 1024 * 1024,
          type: 'raster',
        },
        {
          name: 'tiff',
          size: 11 * 1024 * 1024 * 1024,
          type: 'raster',
        },
        {
          name: 'zip',
          size: 20 * 1024 * 1024,
          type: 'vector',
        },
        {
          name: 'geojson',
          size: 20 * 1024 * 1024,
          type: 'vector',
        },
      ],
    }
  },

  computed: {
    ...mapState('category', ['departments']),
    title() {
      return this.editing ? 'Edit Layer' : 'Upload Layer'
    },
  },
  methods: {
    inputFilter(file, _, prevent) {
      const extension = this.extensions.find(e => e.name === file.name.split('.').pop())
      if (!extension) {
        this.$message(`Invalid file type`, 'error')
        prevent()
      }
      if (file.size > extension.size) {
        this.$message(`File size is too large`, 'error')
        prevent()
      }
      file.type = extension.type
    },
    closeDialog() {
      this.$emit('update:showDialog', false)
      this.files = []
    },
    async createData() {
      try {
        await this.$refs.form.validate()
        if (!this.valid) return
        if (this.files.length === 0) {
          this.$message('File is required', 'error')
          return
        }
        this.loading = true
        this.$store.dispatch('upload/start', {
          name: this.form.name,
          type: this.files[0].type,
          departmentId: this.form.departmentId,
          file: this.files[0].file,
        })
        this.closeDialog()
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
