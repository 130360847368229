<template>
  <v-data-table
    :style="{ height }"
    height="calc(100% - 48px)"
    :headers="getHeaders()"
    :items="tableData"
    disable-pagination
    fixed-header
    :options.sync="sortOptions"
    calculate-widths
    hide-default-footer
    :server-items-length="1"
    disable-filtering
  >
    <template v-slot:top>
      <v-toolbar dense flat>
        <v-text-field
          v-model="params.search"
          style="max-width: 300px"
          label="Search"
          clearable
          hide-details
          dense
          :append-icon="icons.mdiMagnify"
          single-line
          @keyup.enter="$emit('on-search')"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn icon color="primary" @click="$emit('on-create')">
          <v-icon>{{ icons.mdiPlus }}</v-icon>
        </v-btn>

        <v-btn icon color="primary" @click="$emit('refreshed')">
          <v-icon>{{ icons.mdiRefresh }}</v-icon>
        </v-btn>
        <v-dialog v-model="showShareDialog" max-width="450" persistent>
          <v-card>
            <v-card-title>
              <span>Share layer links </span>
            </v-card-title>
            <v-card-text>
              <v-text-field
                label="WMS"
                readonly
                :value="url.wms"
                :append-icon="icons.mdiContentCopy"
                @click:append="copy(url.wms)"
              >
              </v-text-field>
              <v-text-field
                v-if="url.wfs"
                label="WFS"
                readonly
                :value="url.wfs"
                :append-icon="icons.mdiContentCopy"
                @click:append="copy(url.wfs)"
              ></v-text-field>
              <v-text-field
                v-if="url.wcs"
                label="WCS"
                readonly
                :value="url.wcs"
                :append-icon="icons.mdiContentCopy"
                @click:append="copy(url.wcs)"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="showShareDialog = false" text>Close</v-btn>
            </v-card-actions></v-card
          >
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.createdAt="{ item }">
      <span>{{ item.createdAt | datetime }}</span>
    </template>
    <template v-slot:item.isGeoserverPublish="{ item }">
      <v-icon color="primary" v-if="item.isGeoserverPublish">{{ icons.mdiCheck }}</v-icon>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn @click="toggleFavorite(item)" x-small icon color="primary">
        <v-icon title="Toggle favorite layer">{{ item.isFavorite ? icons.mdiStar : icons.mdiStarOutline }}</v-icon>
      </v-btn>

      <v-btn x-small icon color="primary" @click="$emit('on-edit', item)">
        <v-icon>{{ icons.mdiPencil }}</v-icon>
      </v-btn>
      <v-btn icon x-small color="error" @click="handleDelete(item.id)">
        <v-icon>{{ icons.mdiDelete }}</v-icon>
      </v-btn>

      <v-menu offset-y left>
        <template v-slot:activator="{ on, attrs }">
          <v-icon size="18" class="ml-2" v-bind="attrs" v-on="on">
            {{ icons.mdiDotsVertical }}
          </v-icon>
        </template>
        <v-list dense>
          <v-list-item @click="addToMap(item)" :disabled="layerIds.includes(item.id)">
            <v-list-item-title
              ><v-icon size="20">{{ icons.mdiMap }}</v-icon
              ><span class="ml-2">Add to Map</span>
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="download(item)">
            <v-list-item-title
              ><v-icon size="20">{{ icons.mdiDownload }}</v-icon
              ><span class="ml-2">Download</span>
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="!item.isGeoserverPublish" @click="publish(item)">
            <v-list-item-title
              ><v-icon size="20">{{ icons.mdiPublish }}</v-icon
              ><span class="ml-2">Publish</span>
            </v-list-item-title>
          </v-list-item>
          <template v-else>
            <v-list-item @click="publish(item)">
              <v-list-item-title
                ><v-icon size="20">{{ icons.mdiUndo }}</v-icon
                ><span class="ml-2">Unpublish</span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="share(item)">
              <v-list-item-title
                ><v-icon size="20">{{ icons.mdiShare }}</v-icon
                ><span class="ml-2">Share links</span>
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
    </template>
    <template v-slot:item.type="{ item }">
      <v-icon>{{ item | layerType }}</v-icon>
    </template>
    <template v-slot:item.size="{ item }"> {{ item.size | size }} </template>
  </v-data-table>
</template>
<script>
import dataTableMixin from '@/mixins/crud/data-table'
import { toggleFavorite, destroy, togglePublish } from '@/api/layer'
import {
  mdiStarOutline,
  mdiStar,
  mdiMap,
  mdiDotsVertical,
  mdiPublish,
  mdiUndo,
  mdiCheck,
  mdiShare,
  mdiContentCopy,
} from '@mdi/js'
import EventBus from '@/services/event-bus'
import { mapState } from 'vuex'
import Clipboard from 'clipboard'
export default {
  mixins: [dataTableMixin(destroy)],
  data() {
    return {
      icons: {
        mdiStarOutline,
        mdiStar,
        mdiMap,
        mdiDotsVertical,
        mdiPublish,
        mdiUndo,
        mdiCheck,
        mdiShare,
        mdiContentCopy,
      },
      showShareDialog: false,
      url: {
        wms: null,
        wfs: null,
        wcs: null,
      },
    }
  },

  computed: {
    ...mapState('map', ['layerIds']),
    headers() {
      return [
        { text: 'ID', value: 'id' },
        { text: 'Name', value: 'name' },
        { text: 'Department', value: 'department.name' },
        { text: 'Type', value: 'type' },
        { text: 'Size', value: 'size' },
        { text: 'CRS', value: 'crs' },
        { text: 'Created at', value: 'createdAt' },
        { text: 'Publish', value: 'isGeoserverPublish' },
        { text: 'Actions', value: 'actions', align: 'center', sortable: false },
      ]
    },
  },
  methods: {
    copy(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.$message('Copied to clipboard', 'success')
        })
        .catch(err => {
          this.$message('Error copying text: ', err)
        })
    },
    download(layer) {
      window.open(`/api/layers/${layer.id}/download`, '_blank')
    },
    async toggleFavorite(layer) {
      try {
        this.$loader(true)
        await toggleFavorite(layer.id, !layer.isFavorite)
        this.$message('Update favorite layer successfully', 'success')
        this.$emit('deleted')
      } catch (error) {
        console.log(error)
      } finally {
        this.$loader(false)
      }
    },

    addToMap(layer) {
      this.$router.push({ name: 'map-view', params: { layer, tab: 2 } })
      EventBus.$emit(`view-layer-in-map`, layer)
    },

    async publish(layer) {
      try {
        await this.$confirm(
          `Are you sure when ${layer.isGeoserverPublish ? 'unpublishing' : 'publishing'} this layer?`,
          'Warning',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning',
          },
        )
        this.$loader(true)
        await togglePublish(layer.id, !layer.isGeoserverPublish)
        this.$message('Update publish layer successfully', 'success')
        this.$emit('deleted')
      } catch (error) {
        console.log(error)
      } finally {
        this.$loader(false)
      }
    },

    share(layer) {
      this.url = {
        wms: layer.wmsUrl,
        wfs: layer.wfsUrl,
        wcs: layer.wcsUrl,
      }
      this.showShareDialog = true
    },
  },
}
</script>
